import React from "react";
import Layout from "../../../components/Layout";

const PaymentTermsPage = () => {
  const pageTitle = "Payment Terms & Conditions | Unschool";
  const pageDescription =
    "Edupolis Technologies Private Limited shall generate invoices for all payments made on “Unschool” and are subject to the following conditions.";

  return (
    <Layout title={pageTitle} description={pageDescription}>
      <div className="policies-container">
        <h2 className="title">PAYMENT TERMS AND CONDITIONS</h2>
        <p>
          The Payment Terms constitute a contract between you and
          <strong>Edupolis Technologies</strong>
        </p>
        <p>
          <strong>Private Limited</strong>, having its registered address at
          <em>“H.No. 1-11-251/3, Ground Floor,</em>
        </p>
        <p>
          <em>
            Gurumurthy Colony Road, Behind Shoppers Stop, Begumpet, Hyderabad -
            500016,
          </em>
        </p>
        <p>
          <em>Telangana”</em> for the purpose of any payments made for the use
          of Services on thisplatform
          <strong>
            <em>“www.unschool.in”</em>
          </strong>
          .
        </p>
        <p>
          Please read them carefully. You may either accept or decline the Terms
          as indicated at the bottom of this page, but to make payment using the
          Services you must accept the Terms by clicking
          <strong>“I Accept”</strong>. Edupolis Technologies Private Limited
          shall generate invoices for all payments made on
          <strong>“Unschool”</strong> and are subject to the following
          conditions.
        </p>
        <ul>
          <li>
            <strong>1.</strong> <strong>PAYMENT MODES</strong>
          </li>
          <ul>
            <li>American Express</li>
          </ul>
          <ul>
            <li>BHIM UPI</li>
          </ul>
          <ul>
            <li>Google Pay</li>
          </ul>
          <ul>
            <li>PhonePe</li>
          </ul>
          <ul>
            <li> PayTM</li>
          </ul>
          <ul>
            <li>Master Card</li>
          </ul>
          <ul>
            <li> Visa</li>
          </ul>
          <ul>
            <li> Instamojo</li>
          </ul>
        </ul>

        <ul>
          <li>
            <strong>2.</strong>{" "}
            <strong>USER WARRANTS AND REPRESENTATION</strong>
          </li>
          <ul>
            <li>
              You have the appropriate authority to validly accept the Online
              Payment Terms and are able to and will meet your obligations in
              relation to these Terms.
            </li>
          </ul>
          <ul>
            <li>
              The card used in connection with the Services is issued in your
              name or you are authorized to use the card.
            </li>
          </ul>
          <ul>
            <li>
              You will pay the card issuer all charges incurred in the use of
              the Services.
            </li>
          </ul>
          <ul>
            <li>The information supplied by you is true and correct.</li>
          </ul>
        </ul>

        <p>
          When you complete the online payment form, funds will be deducted from
          your Account in INR currency. All payments are debited to
          <strong>Edupolis Technologies</strong>{" "}
          <strong>Private Limited</strong>. Due to the way transactions are
          processed by the external bankingsites, there may be delays of 1-3
          days in updating your payment in Edupolis Technologies Private
          Limited’s records.
        </p>

        <ul>
          <li>
            <strong>3.</strong> <strong>PAYMENT SECURITY</strong>
          </li>
          <ul>
            <li>
              Payments will be processed directly by Authorize.net (INR
              transactions) using Secure Socket Layer (SSL) technology.
            </li>
          </ul>
          <ul>
            <li>
              Card numbers, CVV and Bank PINs are protected with a high level of
              encryption when transmitted over the Internet.
            </li>
          </ul>
          <ul>
            <li>
              Edupolis Technologies Private Limited does not store in any way
              your credit card details.
            </li>
          </ul>
        </ul>

        <ul>
          <li>
            <strong>4.</strong> <strong>CONFIRMATION</strong>
          </li>
          <ul>
            <li>
              If successful, you will receive a confirmation SMS and E-mail of
              your completed payment for the services opted by you followed with
              an E-Invoice towards your payment.
            </li>
          </ul>
          <ul>
            <li>
              If unsuccessful, you will be advised that your payment has failed.
              Edupolis Technologies Private Limited will not be advised why a
              payment has failed; therefore, you should contact your concerned
              Bank for details.
            </li>
          </ul>
          <ul>
            <li>
              If your payment fails, please use one of the other payment methods
              described on our platform for availing the services provided by
              the platform. Please arrange
            </li>
          </ul>
        </ul>

        <p>
          an alternative payment method promptly to ensure continuous use of the
          services on{" "}
          <strong>
            <em>“Unschool”</em>
          </strong>{" "}
          and resources.
        </p>

        <ul>
          <li>
            • Edupolis Technologies Private Limited will confirm your payment
            details via email.
          </li>
        </ul>

        <p>
          By using Edupolis Technologies Private Limited’s online payment
          facilities you accept and consent to your personal data being provided
          to Edupolis Technologies Private Limited and such other third party
          Service Provider for the sole purpose of offering and administering
          the online payment for the services available on the platform.
        </p>

        <p>
          We respect the privacy of every individual who visits our site.
          Edupolis Technologies Private Limited’s websites have security
          measures in place to protect the loss, misuse and alteration of the
          information under our control. Edupolis Technologies Private Limited’s
          Terms and Conditions for payments are subject to change at any time.
          Each transaction shall be subject to the specific Terms and Conditions
          that were in place at the time of the transaction. If you have any
          questions or concerns, please contact support by email at{" "}
          <a href="mailto:admin@unschool.in">admin@unschool.in.</a>
        </p>
        <p>
          <strong>
            Important security note: Never transmit credit card information by
            e-mail.
          </strong>
        </p>
      </div>
    </Layout>
  );
};

export default PaymentTermsPage;
